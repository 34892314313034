import { useMediaQuery } from '@mantine/hooks'
import { isEmpty } from 'lodash'
import Image from 'next/image'
import Link from 'next/link'
import { useLayoutEffect, useMemo, useRef, useState } from 'react'
import { ICourse } from 'stores/courses'

interface ICatalogKlassCardProps {
  course: ICourse
}

const CatalogKlassCard = ({ course }: ICatalogKlassCardProps) => {
  const { courseType, id, title, image, tags, price, promotion, promotionPrice } = course
  const tagRefs = useRef<(HTMLDivElement | null)[]>([])
  const [truncateIndex, setTruncateIndex] = useState<number | null>(null)

  const detailUrl = useMemo(() => {
    return courseType === 'kdc' ? `/onboarding/${id}` : `/courses/technology/${id}`
  }, [courseType, id])

  const isPromotionActive = useMemo(
    () => promotion !== null && (promotion?.promotionRate > 0 || promotion?.promotionAmount > 0),
    [promotion],
  )

  const promotionRate = useMemo(() => {
    if (promotion?.promotionType === null) return 0
    if (promotion?.promotionType === 'rate') return promotion.promotionRate
    else if (promotion?.promotionType === 'amount') {
      const rate = promotion?.promotionAmount / price
      return Math.floor((rate > 1 ? 1 : rate) * 100)
    }
    return 0
  }, [price, promotion])

  useLayoutEffect(() => {
    if (tagRefs.current && tags) {
      const containerWidth = tagRefs.current[0]?.parentElement?.offsetWidth || 0
      let totalWidth = 0

      for (let i = 0; i < tags.length; i++) {
        const tagWidth = tagRefs.current[i]?.offsetWidth || 0
        totalWidth += tagWidth

        if (totalWidth > containerWidth) {
          setTruncateIndex(i)
          break
        }
      }
    }
  }, [tags, tagRefs])

  return (
    <Link href={detailUrl}>
      <ul className="relative flex cursor-pointer flex-col">
        {image ? (
          <div className="relative aspect-[3/2] w-full">
            <Image
              src={image}
              alt="klass_catalog_image"
              layout="fill"
              objectFit="cover"
              className="rounded-md md:rounded-xl"
            />
          </div>
        ) : (
          <div className="aspect-[3/2] w-full rounded-md bg-gray-100 md:rounded-xl" />
        )}
        <li className="mt-2.5 line-clamp-2 text-sm md:text-lg">{title}</li>
        {!isEmpty(tags) && tags && (
          <li className="mt-1.5 flex flex-row space-x-1 overflow-hidden md:mt-1 lg:mt-2 lg:space-x-1.5">
            {tags?.map((tag, idx) => (
              <div
                key={`klass_tag_${idx}`}
                ref={el => (tagRefs.current[idx] = el)}
                className={`whitespace-nowrap rounded bg-gray-100 px-1 py-0.5 text-xs text-gray-400 lg:px-1.5 lg:text-base ${
                  truncateIndex === idx ? 'truncate' : ''
                }`}
                style={{
                  display:
                    truncateIndex !== null && idx >= truncateIndex + 1 ? 'none' : 'inline-block',
                }}>
                {tag.name}
              </div>
            ))}
          </li>
        )}
        {courseType === 'kdc' ? (
          <>
            <li className="mt-2.5 text-xs text-gray-400 line-through md:text-sm lg:mt-3 lg:text-base">
              {price.toLocaleString()}원
            </li>
            <li className="space-x-1 md:mt-0.5 lg:space-x-2">
              <span className="text-sm font-semibold text-yellow-450 md:text-base lg:text-lg">
                국비지원시
              </span>
              <span className="text-sm font-semibold md:text-base lg:text-lg">
                {(Math.round((price * 0.1) / 10) * 10).toLocaleString()}원
              </span>
            </li>
          </>
        ) : (
          <>
            {promotion && promotionPrice && isPromotionActive ? (
              <li className="mt-2.5 flex lg:mt-3">
                <p className="mr-1 text-sm font-medium text-yellow-450 lg:text-base xl:mr-2 xl:text-xl xl:font-semibold">
                  {promotionRate}%
                </p>
                <div className="text-sm font-semibold md:text-base xl:text-xl">
                  {promotionPrice.toLocaleString()}원
                </div>
              </li>
            ) : (
              <li className="mt-2.5 text-sm font-semibold md:text-base lg:mt-3 lg:text-lg">
                {price.toLocaleString()}원
              </li>
            )}
          </>
        )}
      </ul>
    </Link>
  )
}

export default CatalogKlassCard
