import 'dayjs/locale/ko'

import { useMediaQuery } from '@mantine/hooks'
import dayjs from 'dayjs'
import HomeApplyPeriodBadge from 'features/klass/components/HomeApplyPeriodBadge'
import _ from 'lodash'
import Image from 'next/image'
import React, { useCallback } from 'react'
import { ICourse } from 'stores/courses'

import styles from './KDTKlassCard.module.scss'

dayjs.locale('ko')

interface IKDTKlassProps {
  course: ICourse
  handleRouter(course: ICourse, courseId: string): void
}

const HomeKDTCard = ({ course, handleRouter }: IKDTKlassProps) => {
  const { id, title, tags, image, imageM, applyStartedAt, applyEndedAt } = course
  const startDay = dayjs(applyStartedAt).format('YY.MM.DD')
  const endDay = dayjs(applyEndedAt).format('YY.MM.DD')

  const goCourseDetail = useCallback(() => {
    handleRouter(course, id)
  }, [course, handleRouter, id])

  const curDate = dayjs()
  const isBeforeApplyEnd = dayjs(curDate).isBefore(applyEndedAt)

  const isTablet = useMediaQuery('(max-width: 1023px)')
  const schoolTitleNoNumber = title.replaceAll(/[0-9]/g, '')
  const schoolTitle = schoolTitleNoNumber.replaceAll(/[기]/g, '')

  return (
    <li
      className={`cursor-pointer ${isTablet ? '' : styles.HoverImgScale}`}
      onClick={goCourseDetail}>
      <div className="relative flex h-full w-full shrink-0 flex-col rounded-lg">
        {image && (
          <div className="relative aspect-[3/2] shrink-0 overflow-hidden rounded-xl">
            <Image
              src={image}
              alt={title}
              className="h-full w-full"
              layout="fill"
              objectFit="cover"
              priority={true}
            />
          </div>
        )}
        <div className="box-border flex h-full w-full flex-col pt-3 md:pt-2.5 lg:ml-0">
          <p className={`${styles.schoolTitle} text-base font-semibold md:text-xl`}>
            {schoolTitle}
          </p>
          {!_.isEmpty(tags) && (
            <div className="flex items-center space-x-1 lg:space-x-1.5">
              {tags?.map(v => (
                <div
                  key={v.id}
                  className="whitespace-nowrap rounded bg-gray-100 px-1 py-0.5 text-xs text-gray-400 lg:px-1.5 lg:text-base">
                  {v.name}
                </div>
              ))}
            </div>
          )}
          {isBeforeApplyEnd && (
            <span className="mb-2.5 mt-2 block text-xs font-normal text-gray-400 md:mt-3 md:text-base">
              모집기간
              <span className="ml-1 text-label-neutral">
                {startDay} ~ {endDay}
              </span>
            </span>
          )}
          <HomeApplyPeriodBadge size="md" startedAt={applyStartedAt} endedAt={applyEndedAt} />
        </div>
      </div>
    </li>
  )
}
export default HomeKDTCard
