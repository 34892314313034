import { ExternalLinkIcon } from '@heroicons/react/outline'
import Image from 'next/image'
import Link from 'next/link'
import { useRouter } from 'next/router'
import React, { FC, useCallback } from 'react'

import styles from './Footer.module.scss'

interface IFooterSection {
  title: string
}

const FooterSection: FC<IFooterSection> = ({ title, children }) => (
  <div>
    <h3 className="text-xs text-gray-500">{title}</h3>
    {children}
  </div>
)

const iconList = [
  {
    name: 'Instagram',
    icon: '/svg/icon_instagram.svg',
    href: 'https://www.instagram.com/likelion.official/',
  },
  {
    name: 'Youtube',
    icon: '/svg/icon_youtube.svg',
    href: 'https://www.youtube.com/channel/UCYaDkwVaOhuoe_LuFr3lWkA',
  },
  {
    name: 'KakaoTalk',
    icon: '/svg/icon_kakaoch.svg',
    href: 'https://pf.kakao.com/_iyUBM',
  },
  {
    name: 'Brunch',
    icon: '/svg/icon_brunch.svg',
    href: 'https://brunch.co.kr/@likelion',
  },
  {
    name: 'Facebook',
    icon: '/svg/icon_facebook.svg',
    href: 'https://www.facebook.com/likelion.net',
  },
]

const Footer = () => {
  const router = useRouter()
  const goToMain = useCallback(() => router.push('/').then(() => window.scrollTo(0, 0)), [router])

  const KlassList = [
    {
      title: '전체 강의',
      href: '/catalog',
    },
    {
      title: '국비지원 부트캠프',
      href: `${window.bootcampDomain}?utm_source=likelion&utm_medium=likelion_home&utm_campaign=likelion_home`,
      target: '_blank',
      rel: 'noopener noreferrer',
    },
    {
      title: '이벤트',
      href: '/event',
    },
    {
      title: '기업 문의',
      href: '/enterprise',
    },
  ]

  return (
    <footer className="border-t border-gray-300 bg-white" style={{ minWidth: 320 }}>
      <div className="mx-auto max-w-7xl px-4 py-8 xl:px-6">
        <div className="flex flex-col md:flex-row md:justify-between">
          <div
            className="relative h-[16px] w-[144px] cursor-pointer md:h-[20px] md:w-45"
            onClick={goToMain}>
            <Image src="/img/likelion_kor_black_logo.svg" alt="likelion" layout="fill" />
          </div>
          {/* 오른쪽 메뉴 시작 */}
          <div className="mt-8 flex flex-col space-y-7 md:mt-0 md:flex-row md:space-x-20 md:space-y-0 xl:space-x-28">
            <FooterSection title="모든 교육 경험">
              <ul className="mt-4 space-y-3">
                {KlassList.map(({ title, href, target, rel }, idx) => (
                  <li className="text-sm font-semibold" key={`${title}_${idx}`}>
                    <Link href={href} passHref>
                      <a target={target} rel={rel}>
                        {title}
                      </a>
                    </Link>
                  </li>
                ))}
              </ul>
            </FooterSection>
            <FooterSection title="회사 소개">
              <ul className="mt-3 flex flex-col gap-y-3">
                <li className="flex items-center gap-x-1">
                  <a
                    href="https://likelion.career.greetinghr.com"
                    target="_blank"
                    className="flex items-center text-sm font-semibold"
                    rel="noreferrer noopener">
                    브랜드 웹사이트
                  </a>
                  <ExternalLinkIcon className="w-6" />
                </li>
                <li className="flex items-center gap-x-1">
                  <a
                    href="https://likelion.notion.site/LIKELION-BRAND-GUIDELINE-942a09c286b1401b83f9193c0787759e"
                    target="_blank"
                    className="flex items-center text-sm font-semibold"
                    rel="noreferrer noopener">
                    브랜드 가이드라인
                  </a>
                  <ExternalLinkIcon className="w-6" />
                </li>
                <li className="flex items-center gap-x-1">
                  <a
                    href="https://likelion.career.greetinghr.com/career"
                    target="_blank"
                    className="flex items-center text-sm font-semibold"
                    rel="noreferrer noopener">
                    채용
                  </a>
                  <ExternalLinkIcon className="w-6" />
                </li>
              </ul>
            </FooterSection>
          </div>
        </div>
        {/* 구분선 */}
        <hr className="mt-5 w-full border-gray-300 lg:mt-8" />
        {/* 이용약관 */}
        <div className="mt-4">
          <ul className="mb-6 flex space-x-3">
            {iconList.map(({ name, icon, href }) => (
              <li key={name} className="text-sm">
                <a
                  target="_blank"
                  href={href}
                  rel="noreferrer noopener"
                  className="flex h-7 w-7 items-center lg:h-6 lg:w-6">
                  <img src={icon} alt={name} className="h-6 w-6" />
                </a>
              </li>
            ))}
          </ul>
          <div className="flex items-center space-x-2 text-xs font-medium">
            <a
              href="https://likelion.notion.site/LIKELION-89ba1354b98d4825af14109aebdd3af9"
              className="text-gray-500 "
              target="_blank"
              rel="noopener noreferrer nofollow">
              이용약관
            </a>
            <span className="h-1 w-1 rounded-full bg-gray-500"></span>
            <a
              href="https://likelion.notion.site/LIKELION-4d3c7ce22a724b3c99950e853dc7589b"
              className="text-gray-500"
              target="_blank"
              rel="noopener noreferrer nofollow">
              개인정보처리방침
            </a>
            <span className="h-1 w-1 rounded-full bg-gray-500"></span>
            <a
              href="https://likelion.notion.site/LIKELION-e9139bf291ad4e0b88f8b99f8597d04a"
              className="text-gray-500"
              target="_blank"
              rel="noopener noreferrer nofollow">
              환불규정
            </a>
          </div>
          <div className="mt-3">
            <div className="flex flex-col space-y-0.5 md:flex-row md:space-y-0">
              <div className={'text-xs text-gray-500'}>
                <span className={styles.corp}>상호명: 멋쟁이사자처럼</span>
                <span className={styles.corp}>대표: 나성영</span>
                <span className={styles.corp}>contact@likelion.net</span>
              </div>
              <div className={'flex text-[10px] text-gray-500 md:text-xs'}>
                <span className={styles.corp}>사업자 번호 : 264-88-01106</span>
                <span>통신판매업 신고번호 : 2022-서울종로-1534</span>
              </div>
            </div>
            <div
              className={
                'mt-0.5 flex flex-col space-y-0.5 text-[10px] text-gray-500 md:mt-1 md:flex-row md:space-x-2 md:space-y-0 md:text-xs'
              }>
              <span>주소 : 서울 종로구 종로3길17, 광화문D타워 D1동 16층, 17층</span>
              <span>Copyright © 2022 멋쟁이사자처럼 All rights reserved.</span>
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
