import Link from 'next/link'
import { useRouter } from 'next/router'

import { NavTag } from './NavTag'

const HeaderNav = () => {
  const router = useRouter()

  const navList = [
    {
      title: '전체강의',
      href: '/catalog',
    },
    {
      title: '부트캠프',
      href: `${window.bootcampDomain}?utm_source=likelion&utm_medium=likelion_home&utm_campaign=likelion_home`,
      tag: <NavTag name="국비지원" />,
      target: '_blank',
      rel: 'noopener noreferrer',
    },
    {
      title: '이벤트',
      href: '/event',
    },
    {
      title: '기업 문의',
      href: '/enterprise',
    },
  ]

  return (
    <nav>
      <ul className="hidden lg:flex">
        {navList.map(({ title, href, tag, target, rel }) => {
          const pathName = router.pathname.split('/')[1]
          const hrefName = target ? 'bootcamp' : href.split('/')[1]

          return (
            <li key={`${title}_${href}`} className="flex flex-row items-center">
              <Link href={href} as={href} passHref>
                <a
                  target={target}
                  rel={rel}
                  className={`relative flex cursor-pointer items-center space-x-1.5 py-5 font-semibold ${
                    pathName === hrefName ? 'text-yellow-450' : ''
                  } ${tag ? 'px-3' : 'px-4'}`}>
                  <span>{title}</span>
                  {tag && <>{tag}</>}
                </a>
              </Link>
            </li>
          )
        })}
      </ul>
    </nav>
  )
}

export default HeaderNav
