import _ from 'lodash'
import { useRouter } from 'next/router'
import { useCallback } from 'react'
import { onSelectKdtItem } from 'shared/gtm/onSelectKdtItem'
import { ICourse } from 'stores/courses'

import HomeKDTCard from './HomeKDTCard'

interface IKDTKlassProps {
  course: ICourse
}

const KDTKlassCard = ({ course }: IKDTKlassProps) => {
  const router = useRouter()
  const handleRouter = useCallback(
    (course: ICourse, courseId: string) => {
      return window.open(
        `${window.bootcampDomain}/school/${courseId}?utm_source=likelion&utm_medium=likelion_home&utm_campaign=likelion_home`,
        '_blank',
        'noopener, noreferrer',
      )
    },
    [router],
  )
  return <HomeKDTCard course={course} handleRouter={handleRouter} />
}

export default KDTKlassCard
